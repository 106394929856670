<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.name"
        placeholder="请输入学生姓名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.$index + 1 }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.name }}
        </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.account }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.college.school.name }}
        </template>
      </el-table-column>
      <el-table-column label="报名时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.user.is_normal == 1"
            size="mini"
            type="danger"
            @click="handleForbid(scope.row.user_id, '冻结')"
            >冻结</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleForbid(scope.row.user_id, '解冻')"
            >解冻</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        other_course_school_id:'',
        name: "",
        is_normal: "",
      },
    };
  },
  created() {
    this.listQuery.other_course_school_id=this.$route.query.id
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/otherCourse/enrollList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/user/forbid",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>